<ion-content>
  @if (!transactions) {
    <operation-in-progress />
  } @else if (transactions?.length > 0) {
    <ion-list lines="none" id="transactions">
      @if (transactions?.length > 0 && paginatorConfig?.pages > 1) {
        <div class="row paginator">
          <mat-paginator
            #paginator
            (page)="handlePageEvent($event)"
            [length]="paginatorConfig?.rowsCount"
            [pageSize]="paginatorConfig?.pageSize"
            [disabled]="loadingMore"
            [showFirstLastButtons]="false"
            [hidePageSize]="true"
            [pageIndex]="paginatorConfig.page - 1"
          />
        </div>
      }

      @for (transaction of transactions; track $index) {
        <ion-card class="prevent-animation transaction" [routerLink]="['/home', 'orders', 'details', transaction.id_mercado_pago]" routerLinkActive="router-link-active" [state]="{ transaction }">
          <ion-card-content>
            <ion-text class="bold"> {{ transaction.raffle?.name }} </ion-text>
            <section class="flex space-between align-center">
              <ion-text color="medium"> {{ transaction.raffle?.award }} </ion-text>
              <ion-text color="success" class="bold">{{ transaction.amount_cash | currency }} </ion-text>
              <!-- <ion-chip color="success" class="bold">{{ transaction.amount_cash | currency }} </ion-chip> -->
            </section>
            <ion-text class="flex space-between align-center">
              <ion-badge [color]="colorStatus[transaction.status]" class="ion-text-lowercase"> Pagamento {{ statusesTransaction[transaction.status] }} </ion-badge>
              <ion-text color="dark"> {{ transaction.amount_numbers }} cotas </ion-text>
            </ion-text>
          </ion-card-content>
        </ion-card>
      }
    </ion-list>
  } @else {
    <ion-card class="prevent-animation">
      <ion-card-content>
        <ion-text class="bold"> Nenhuma transação encontrada </ion-text>
      </ion-card-content>
    </ion-card>
  }
</ion-content>
