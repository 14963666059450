<ion-content id="container">
  <div id="content">
    @if (raffle | async) {
      <div id="raffle" class="margin-sm padding-sm border-radius-md">
        <div id="images">
          <h2 id="title" class="bold padding-vertical-md" [class.ion-text-center]="isMobile">{{ (raffle | async).name | uppercase }}</h2>
          <images [images]="(raffle | async)?.image" />

          @if ((raffle | async)?.image?.length === 0) {
            <ion-text id="no-image"> Nenhuma imagem disponível </ion-text>
          }
        </div>

        <ion-grid id="chips">
          <ion-row>
            <ion-col size="6" class="ion-text-center">
              <ion-chip color="success" class="flex-column gap-sm">
                <ion-note color="dark">Valor da cota</ion-note>
                <ion-text class="bold">{{ (raffle | async).quota_value | currency }}</ion-text>
              </ion-chip>
            </ion-col>
            <ion-col size="6" class="ion-text-center">
              <ion-chip color="success" class="flex-column gap-sm">
                <ion-note color="dark">Sorteio</ion-note>
                <ion-text class="bold">{{ (raffle | async).draw_prediction | date: "dd/MM/yyyy" }}</ion-text>
              </ion-chip>
            </ion-col>
          </ion-row>
        </ion-grid>

        <label id="award" class="flex-column gap-sm">
          <h3 class="bold">{{ (raffle | async).award }}</h3>
          <ion-text>{{ (raffle | async).description_award }}</ion-text>
        </label>

        <div id="select-numbers">
          <h4 class="bold padding-vertical-md" [class.ion-text-center]="isMobile">
            Quantidade de números <ion-note>(min: {{ min }})</ion-note>
          </h4>
          <div id="select-numbers-manual">
            <div class="start">
              <button [disabled]="(raffle | async).qtd_array === 0" class="minus-amount" (click)="changeAmount(amountButton, 'minus')">-{{ amountButton }}</button>
              <button [disabled]="(raffle | async).qtd_array === 0" class="minus" (click)="changeAmount(1, 'minus')">
                <ion-icon name="remove-outline" />
              </button>
            </div>
            <!-- <ion-text id="amount-numbers"> {{ selectedNumbers }} </ion-text> -->
            <input [disabled]="(raffle | async).qtd_array === 0" id="amount-numbers" type="number" [(ngModel)]="selectedNumbers" [min]="min" [max]="max" (blur)="selectedNumbersChanged()" />
            <div class="end">
              <button [disabled]="(raffle | async).qtd_array === 0" class="plus" (click)="changeAmount(1, 'plus')">
                <ion-icon name="add-outline" />
              </button>
              <button [disabled]="(raffle | async).qtd_array === 0" class="plus-amount" (click)="changeAmount(amountButton, 'plus')">+{{ amountButton }}</button>
            </div>
          </div>

          @if ((raffle | async)?.qtd_array > 0) {
            <ion-range mode="md" class="margin-inline-md margin-top-md" aria-label="Quantidade de números" [min]="min" [max]="max" [(ngModel)]="selectedNumbers" (ionChange)="selectedNumbersChanged()" />
          }
        </div>

        <div id="finish">
          <ion-button fill="solid" (click)="buyRaffles()"> Pagar {{ selectedNumbers * (raffle | async).quota_value | currency }} </ion-button>
        </div>

        @if (combos?.length > 0) {
          <div id="combos">
            <h4 class="bold padding-vertical-md" [class.ion-text-center]="isMobile">Combos populares</h4>
            <div id="combos-list">
              @for (amount of combos; track $index) {
                <div class="combo">
                  <ion-chip color="dark" class="flex-column gap-sm" (click)="setAmount(amount)">
                    <div class="content flex-column gap-sm">
                      <ion-text class="amount bold"> {{ amount }} </ion-text>
                      <ion-text color="success"> {{ amount * (raffle | async).quota_value | currency }} </ion-text>
                    </div>
                  </ion-chip>
                </div>
              }
            </div>
          </div>
        }

        <!-- @if ((raffle | async)?.flash_numbers?.length > 0) {
          <div id="flash-numbers">
            <h4 class="bold padding-vertical-md" [class.ion-text-center]="isMobile">Números Relâmpagos</h4>
            <div id="flash-numbers-list">
              @for (number of (raffle | async).flash_numbers; track $index) {
                <mat-chip-grid #chipGrid>
                  @for (flashNumber of (raffle | async)?.flash_numbers; track flashNumber) {
                    <mat-chip-row [editable]="false">
                      {{ flashNumber }}
                      <button matChipRemove>
                        <mat-icon>cancel</mat-icon>
                      </button>
                    </mat-chip-row>
                  }
                  <input [disabled]="true" type="number" [matChipInputFor]="chipGrid" />
                </mat-chip-grid>
              }
            </div>
          </div>
        } -->
      </div>
    } @else {
      <operation-in-progress [message]="operationInProgress" />
    }
  </div>
</ion-content>
