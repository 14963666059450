import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import {
  BaseClass,
  Breadcrumb,
  BreadcrumbsComponent,
  ColorsStatusTransaction,
  OperationInProgressComponent,
  PadStartPipe,
  Raffle,
  RaffleService,
  StatusesTransaction,
  TAXA_MERCADO_PAGO,
  ToastService,
  Transaction,
  TransactionService,
  FirebaseTransactionsService,
  copyClipboard,
  SocialComponent,
} from '@common/globals';
import { IonContent, IonCard, IonCardSubtitle, IonCardHeader, IonCardContent, IonTextarea, IonText, IonCardTitle, IonChip, IonBadge, IonList, IonLabel, IonItem, IonNote, IonButton, IonButtons, IonIcon } from "@ionic/angular/standalone";
import { CurrencyPipe } from '@angular/common';
import { RoundNumberPipe } from '../../../../../../../common/src/lib/pipes/round-number.pipe';

@Component({
  selector: 'app-details-transaction',
  templateUrl: './details-transaction.component.html',
  styleUrls: ['./details-transaction.component.scss'],
  standalone: true,
  imports: [
    IonIcon,
    IonButtons,
    IonButton,
    IonNote,
    IonItem,
    IonLabel,
    IonList,
    IonBadge,
    IonChip,
    IonCardTitle,
    IonText,
    IonTextarea,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCard,
    IonContent,
    OperationInProgressComponent,
    CurrencyPipe,
    BreadcrumbsComponent,
    PadStartPipe,
    RoundNumberPipe,
    RouterLink,
    SocialComponent,
  ],
})
export class DetailsTransactionComponent extends BaseClass() implements OnInit {

  breadcrumbs: Array<Breadcrumb> = [
    { link: '/home', text: 'Home' },
    { link: '/home/orders', text: 'Meus Pedidos' },
    { link: null, text: 'Detalhes do Pedido' },
  ]

  transaction: Transaction
  operationInProgress: boolean = true;
  id_mercado_pago: string;

  statusesTransaction = StatusesTransaction;
  colorsStatussesTransaction = ColorsStatusTransaction;

  raffle: Raffle;

  taxaMP = TAXA_MERCADO_PAGO;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private firebaseTransactionsService: FirebaseTransactionsService,
    private transactionService: TransactionService,
    private raffleService: RaffleService,
    private toastService: ToastService,
  ) {
    super();
    const routerState = this.router.getCurrentNavigation()?.extras?.state?.['transaction'];
    if (routerState) this.transaction = routerState;
  }

  ngOnInit() {
    this.initialize();
  }

  private async initialize() {
    const sub = this.route.params.subscribe({
      next: (params) => {
        if (params?.['id_mercado_pago']) {
          this.id_mercado_pago = params?.['id_mercado_pago'];

          if (!this.transaction?.raffle_id)
            this.getDetailsTransaction(this.id_mercado_pago);
        }
      }
    });

    this.appendSubscription(sub);
  }

  async getDetailsTransaction(id_mercado_pago: string) {
    const unsubscribeSnap = this.firebaseTransactionsService.getTransactionById(id_mercado_pago, (snap) => {
      const data: Transaction = {
        ...snap.data(),
        id: snap.id,
      };

      this.transaction = { ...data, purchased: data?.purchased || this.transaction?.purchased };
      if (!this.raffle) this.getRaffle();

      if (this.transaction?.status === 'PAID' && unsubscribeSnap) {
        this.getDetailsTransactionAPI(this.id_mercado_pago);
        unsubscribeSnap();
      }
    });
  }

  async getDetailsTransactionAPI(is_mercado_pago: string) {
    this.transactionService.getTransactionByIdMP(is_mercado_pago).then((res) => {
      if (res) this.transaction = res.transaction;
    })
  }

  getRaffle() {
    this.raffleService.getRaffleById(this.transaction.raffle_id).then((res) => {
      this.raffle = res;
    }).catch((err) => {
      console.log(err);
    });
  }

  async copyCodigoPix() {
    const copied = await copyClipboard(this.transaction?.pix_copia_cola);
    if (copied) return this.toastService.present({ message: 'Código copiado', color: 'success' });
    this.toastService.present({ message: 'Erro ao copiar código', color: 'danger' });
  }

  buyMore() {
    this.router.navigate(['/home', 'promotion', btoa(this.raffle.id)]);
  }
}
