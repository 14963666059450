import { AsyncPipe } from '@angular/common';
import { Component } from '@angular/core';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { IonList, IonIcon, IonMenuToggle, IonLabel, IonItem, IonContent } from '@ionic/angular/standalone';
import { AuthService, session$ } from '@common';
import { BaseClass } from '@common';
import { addIcons } from 'ionicons';
import { home, homeOutline, ticket, ticketOutline, people, peopleOutline, settings, settingsOutline, logOut, logOutOutline } from 'ionicons/icons';

addIcons({ home, homeOutline, ticket, ticketOutline, people, peopleOutline, settings, settingsOutline, logOut, logOutOutline })

@Component({
  selector: 'sidenav',
  templateUrl: './sidenav.component.html',
  standalone: true,
imports: [IonContent, IonItem, RouterLink, RouterLinkActive, IonList, IonIcon, IonMenuToggle, IonLabel, AsyncPipe],
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent extends BaseClass() {

  session = session$;

  constructor(
    private authService: AuthService,
    private router: Router,
  ) {
    super();
  }

  pages: Array<AppPage> = [
    { title: 'Meus Pedidos', icon: 'ticket', path: ['/home', 'orders'] },
    { title: 'Meu Perfil', icon: 'people', path: ['/home', 'profile'] },
  ];

  logout() {
    this.authService.logout();
    this.router.navigate(['/login'], { replaceUrl: true });
  }

}

export type AppPage = {
  title: string;
  icon: string;
  path: Array<string>;
}
