<ion-content>
  <div id="container">
    <ion-list id="left">
      <div>
        <ion-avatar>
          <img [src]="logoUrl" />
        </ion-avatar>

        @if (!isMobile) {
          <h1 class="linear-g-color">Portal da Sorte</h1>

          <p>{{ fraseEfeito }}</p>
        }
      </div>
    </ion-list>

    <ion-list id="right" [formGroup]="form">
      <div>
        <section id="header">
          <h1>Bem Vindo!</h1>
          <ion-note>Preencha o formulário para se cadastrar</ion-note>
        </section>

        <section id="form">
          <mat-form-field id="cpf" appearance="outline">
            <input type="text" matInput placeholder="CPF" formControlName="cpf" mask="000.000.000-00" />
          </mat-form-field>

          <mat-form-field id="name" appearance="outline">
            <input type="text" matInput placeholder="Nome" formControlName="name" />
          </mat-form-field>

          <mat-form-field id="email" appearance="outline">
            <input type="email" matInput placeholder="E-mail" formControlName="email" />
          </mat-form-field>

          <mat-form-field id="phone" appearance="outline">
            <input type="text" matInput placeholder="Telefone" formControlName="phone" mask="(00) 00000-0000" />
          </mat-form-field>

          <mat-form-field id="password" appearance="outline">
            <input type="password" matInput placeholder="Senha" formControlName="password" />
          </mat-form-field>

          <mat-form-field id="confirm-password" appearance="outline">
            <input type="password" matInput placeholder="Confirme a senha" formControlName="confirmPassword" />
          </mat-form-field>

          <ion-button [disabled]="form.disabled" fill="solid" (click)="register()">CRIAR CONTA</ion-button>
        </section>

        <section class="flex space-between">
          <span>
            <ion-text> Já tem conta? </ion-text>
            <ion-text class="linear-g-color">
              <a [routerLink]="['/login']" [queryParams]="{ redirect }" class="bold"> Login </a>
            </ion-text>
          </span>
        </section>
      </div>
    </ion-list>

    @if (!isMobile) {
      <hr />
    }
  </div>
</ion-content>
