import { Routes } from '@angular/router';
import { ChangePasswordComponent, LoginComponentCommon, NotFoundComponent, authGuard, matchUrlChangePassword } from '@common/components';
import { APP_NAME } from '@common/globals';
import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './home/profile/profile.component';
import { RaffleComponent } from './home/raffle-wrapper/raffle/raffle.component';
import { PaymentComponent } from './home/raffle-wrapper/raffle/payment/payment.component';
import { RaffleWrapperComponent } from './home/raffle-wrapper/raffle-wrapper.component';
import { RegisterComponent } from './register/register.component';
import { TransactionsComponent } from './home/transactions-wrapper/transactions/transactions.component';
import { DetailsTransactionComponent } from './home/transactions-wrapper/transactions/details-transaction/details-transaction.component';
import { TransactionsWrapperComponent } from './home/transactions-wrapper/transactions-wrapper.component';
import { ForgotPasswordComponent } from '@common/components';
import { RafflesBookieComponent } from './home/raffle-wrapper/raffles-bookie/raffles-bookie.component';

export const routes: Routes = [
  { path: 'login', component: LoginComponentCommon, title: APP_NAME + ' - Login' },
  { path: 'register', component: RegisterComponent, title: APP_NAME + ' - Cadastro', },
  { path: 'forgot-password', component: ForgotPasswordComponent, title: APP_NAME + ' - Esqueci a Senha' },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [authGuard],
    title: APP_NAME + ' - Home',
    children: [
      {
        path: 'orders',
        component: TransactionsWrapperComponent,
        canActivate: [authGuard],
        title: APP_NAME + ' - Meus Pedidos',
        children: [
          { path: 'details/:id_mercado_pago', component: DetailsTransactionComponent, title: APP_NAME + ' - Detalhes do Pedido' },
          { path: '', component: TransactionsComponent, title: APP_NAME + ' - Meus Pedidos' },
        ],
      },
      {
        path: 'promotion/:id',
        component: RaffleWrapperComponent,
        title: APP_NAME + ' - Rifa',
        children: [
          { path: 'payment', component: PaymentComponent, title: APP_NAME + ' - Finalizar Compra' },
          { path: '', component: RaffleComponent, title: APP_NAME + ' - Rifa' },
        ],
      },
      { path: 'profile', component: ProfileComponent, canActivate: [authGuard], title: APP_NAME + ' - Perfil' },
      { path: 'raffles-bookie/:bookie_id', component: RafflesBookieComponent, title: APP_NAME + ' - Rifa', },
      { path: '', redirectTo: 'orders', pathMatch: 'full', },
    ],
  },
  { path: '', redirectTo: 'login', pathMatch: 'full', },
  { matcher: matchUrlChangePassword, component: ChangePasswordComponent, title: APP_NAME + ' - Alterar Senha' }, // keep at the end to prevent unecessary checks
  { path: '**', component: NotFoundComponent, title: APP_NAME + ' - Not Found' },
];
