import { Pipe, PipeTransform } from '@angular/core';
import { roundDown, roundUp } from '../utils';

@Pipe({
  name: 'roundNumber',
  standalone: true
})
export class RoundNumberPipe implements PipeTransform {

  transform(value: number, direction: 'UP' | 'DOWN'): number {
    if (value == null) return 0;
    return direction === 'UP' ? roundUp(value, 2) : roundDown(value, 2);
  }

}
