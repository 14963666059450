import { AsyncPipe } from '@angular/common';
import { Component } from '@angular/core';
import {
  BaseClass,
  Client,
  ClientService,
  LoadingService,
  MIN_USERNAME_LENGTH,
  OperationInProgressComponent,
  SessionStorageService,
  ToastService,
  cpfIsValid,
  getCurrentSession,
} from '@common/globals';
import {
  IonContent,
  IonList,
  IonCard,
  IonText,
  IonCardContent,
  IonListHeader,
  IonButton,
} from "@ionic/angular/standalone";
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgxMaskDirective } from 'ngx-mask';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  standalone: true,
  imports: [IonButton, IonListHeader, IonCardContent, IonText, IonCard, IonList, IonContent, AsyncPipe, FormsModule, OperationInProgressComponent, MatFormFieldModule, MatInputModule, ReactiveFormsModule, NgxMaskDirective],
})
export class ProfileComponent extends BaseClass() {

  client: Client;

  form: FormGroup<{
    name: FormControl<string>,
    email: FormControl<string>,
    phone: FormControl<string>,
    cpf: FormControl<string>,
  }> = new FormGroup({
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    phone: new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(11)]),
    cpf: new FormControl('', [Validators.required, Validators.minLength(11), Validators.maxLength(11)]),
  });

  session = getCurrentSession<'client'>();

  constructor(
    private toastService: ToastService,
    private clientService: ClientService,
    private loadingService: LoadingService,
    private sessionStorageService: SessionStorageService,
  ) {
    super();
  }

  ngOnInit() {
    const sub = (this.session).subscribe({
      next: (session) => {
        if (session?.client) {
          this.client = session.client;
          this.form.setValue({
            name: this.client.name,
            email: this.client.email,
            phone: this.client.phone,
            cpf: this.client.cpf,
          });
        }
      }
    });

    this.appendSubscription(sub);
  }

  async save() {
    if (!this.client?.id) return this.toastService.present({ message: 'Erro ao salvar alterações.', color: 'danger' });

    if (this.form.pristine) return this.toastService.present({ message: 'Nenhuma alteração foi feita.', color: 'warning' });
    if (this.form.invalid) return this.toastService.present({ message: 'Verifique se todos os campos estão preenchidos corretamente.', color: 'danger' });

    const formValue: Partial<Client> = this.form.value;

    if (formValue.name?.length < MIN_USERNAME_LENGTH) return this.toastService.present({ message: `O nome deve ter no mínimo ${MIN_USERNAME_LENGTH} caracteres.`, color: 'danger' });
    if (!cpfIsValid(formValue.cpf)) return this.toastService.present({ message: 'CPF inválido.', color: 'danger' });

    const loading = await this.loadingService.presentLoading("Salvando alterações...");
    this.form.disable();

    try {
      const res = await this.clientService.update(this.client.id, formValue);
      if (res.response === 200) {
        this.toastService.present({ message: 'Alterações salvas com sucesso.', color: 'success' });
        this.sessionStorageService.setSession<'client'>({ ...this.session?.value, client: { ...this.client, ...formValue } });
      } else {
        this.toastService.present({ message: 'Erro ao salvar alterações.', color: 'danger' });
      }
    } catch (error) {
      this.toastService.present({ message: 'Erro ao salvar alterações.', color: 'danger' });
      console.log(error);
    } finally {
      loading.dismiss();
      this.form.enable();
    }
  }

}
