<ion-content>
  <div id="container">
    <ion-header>
      <ion-toolbar>
        <ion-title>
          <div id="logo-bookie">
            @if (bookie?.logo) {
              <ion-img [src]="bookie?.logo" />
            } @else {
              <ion-text class="linear-g-color"> {{ bookie?.name | uppercase }} </ion-text>
            }
          </div>
          <social [bookie]="bookie" />
        </ion-title>
      </ion-toolbar>
    </ion-header>

    <div id="content">
      <raffles-list [showAllButton]="false" [raffles]="raffles" app="client" />
    </div>
  </div>
</ion-content>
