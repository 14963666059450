<div id="content">
  <breadcrumbs [breadcrumbs]="breadcrumbs" />

  @if (transaction?.status) {
    <ion-list lines="none" id="award">
      <ion-item>
        <ion-label class="flex-column">
          <h1>
            <ion-text class="bold linear-g-color"> {{ raffle?.name }} </ion-text>
          </h1>
          <ion-text> {{ raffle?.award }} </ion-text>

          <ion-note class="margin-block-md"> {{ raffle?.description_award }} </ion-note>

          <div class="flex">
            <ion-button [disabled]="!raffle?.id" size="small" fill="solid" (click)="buyMore()">
              <ion-text> Comprar mais </ion-text>
            </ion-button>

            <ion-button [disabled]="!raffle?.bookie?.id" size="small" fill="outline" [routerLink]="['/home', 'raffles-bookie', raffle?.bookie?.id]">
              <ion-text> Outras ações desse criador </ion-text>
            </ion-button>
          </div>
        </ion-label>
      </ion-item>
    </ion-list>
  }

  @if (transaction) {
    @switch (transaction?.status) {
      @case ("PENDING") {
        <ion-card id="pix" class="prevent-animation" mode="md">
          <div id="qr">
            <div class="corner tl"></div>
            <div class="corner tr"></div>
            <div class="corner bl"></div>
            <div class="corner br"></div>
            <img [src]="'data:image/png;base64,' + transaction.pix_qr_code" />
          </div>

          <ion-card-header>
            <ion-card-subtitle> Transação #{{ transaction.id_mercado_pago || transaction.id }} </ion-card-subtitle>
            <ion-card-title class="linear-g-color bold"> Agora é só pagar </ion-card-title>

            <h4>
              <ion-text class="bold"> Quantidade de Cotas: </ion-text>
              <ion-text class="bold linear-g-color"> {{ transaction.amount_numbers }} </ion-text>
            </h4>

            <h4>
              <ion-text class="bold"> Valor da Cota: </ion-text>
              <ion-text class="bold linear-g-color"> {{ raffle?.quota_value | currency }} </ion-text>
            </h4>

            <h4>
              <ion-text class="bold"> Subtotal: </ion-text>
              <ion-text class="bold linear-g-color"> {{ raffle?.quota_value * transaction.amount_numbers | roundNumber: "UP" | currency }} </ion-text>
            </h4>

            <h4>
              <ion-text class="bold"> Taxas: </ion-text>
              <ion-text class="bold linear-g-color"> {{ raffle?.quota_value * transaction.amount_numbers * taxaMP | roundNumber: "UP" | currency }} </ion-text>
            </h4>

            <h4>
              <ion-text class="bold"> Total à pagar: </ion-text>
              <ion-text class="bold linear-g-color"> {{ transaction.amount_cash | currency }} </ion-text>
            </h4>

            <h4>
              <ion-badge class="bold" [color]="colorsStatussesTransaction[transaction.status]"> Pagamento {{ statusesTransaction[transaction.status] }} </ion-badge>
            </h4>
          </ion-card-header>

          <ion-card-content>
            <h6 class="margin-block-md">Leia o código QR acima com o aplicativo do seu banco para realizar o pagamento</h6>
            <h6>Ou, se preferir, copie o código abaixo e cole no aplicativo do seu banco:</h6>

            <ion-textarea (click)="copyCodigoPix()" class="margin-top-md" [readonly]="true" [value]="transaction.pix_copia_cola" [autoGrow]="true" />

            <h6 class="margin-block-md">
              <span class="bold">Fique atento!</span>
              <li><ion-note> O seu pedido só é concluído quando o pagamento do PIX é confirmado. </ion-note></li>
              <li><ion-note> Os seus números da sorte só serão revelados após o pagamento </ion-note></li>
              <li><ion-note> O seu dinheiro está seguro conosco: caso ocorra algum problema com o seu pedido o dinheiro é estornado imediatamente e de forma automática para a sua conta </ion-note></li>
            </h6>
          </ion-card-content>
        </ion-card>
      }
      @case ("PAID") {
        <ion-card id="pix" class="prevent-animation" mode="md">
          <ion-card-header>
            <ion-card-subtitle> Transação #{{ transaction.id_mercado_pago || transaction.id }} </ion-card-subtitle>
          </ion-card-header>

          <ion-card-content>
            <h6>
              <ion-text class="bold"> Quantidade de Cotas: </ion-text>
              <ion-text class="bold linear-g-color"> {{ transaction.amount_numbers }} </ion-text>
            </h6>

            <h6>
              <ion-text class="bold"> Valor da Cota: </ion-text>
              <ion-text class="bold linear-g-color"> {{ raffle?.quota_value | currency }} </ion-text>
            </h6>

            <h6>
              <ion-text class="bold"> Valor Total: </ion-text>
              <ion-text class="bold linear-g-color"> {{ transaction.amount_cash | currency }} </ion-text>
            </h6>

            <h6>
              <ion-badge class="bold" [color]="colorsStatussesTransaction[transaction.status]"> Pagamento {{ statusesTransaction[transaction.status] }} </ion-badge>
            </h6>

            <h2 class="flex-column">
              <!-- <ion-text class="linear-g-color bold">Pagamento confirmado!</ion-text> -->
              <ion-text> Que a sorte esteja com você!</ion-text>
            </h2>

            @if (transaction.purchased?.length > 0) {
              <h2 class="margin-top-md">
                <ion-text class="bold"> Seus números da sorte: </ion-text>
                <section class="margin-top-md" id="purchased-numbers">
                  @for (num of transaction.purchased; track $index) {
                    <li [class.winner]="transaction?.flashNumbers?.includes(num)">{{ num | padStart: raffle?.amount_numbers + raffle?.start_at }}</li>
                  }
                </section>
              </h2>
            }
          </ion-card-content>
        </ion-card>
      }

      @case ("REFUND") {
        <ion-card id="pix" class="prevent-animation" mode="md">
          <ion-card-header>
            <ion-card-subtitle> Transação #{{ transaction.id_mercado_pago || transaction.id }} </ion-card-subtitle>
          </ion-card-header>

          <ion-card-content>
            <h6>
              <ion-text class="bold"> Quantidade de Cotas: </ion-text>
              <ion-text class="bold linear-g-color"> {{ transaction.amount_numbers }} </ion-text>
            </h6>

            <h6>
              <ion-text class="bold"> Valor da Cota: </ion-text>
              <ion-text class="bold linear-g-color"> {{ raffle?.quota_value | currency }} </ion-text>
            </h6>

            <h6>
              <ion-text class="bold"> Valor Total: </ion-text>
              <ion-text class="bold linear-g-color"> {{ transaction.amount_cash | currency }} </ion-text>
            </h6>

            <h6>
              <ion-badge class="bold" [color]="colorsStatussesTransaction[transaction.status]"> Pagamento {{ statusesTransaction[transaction.status] }} </ion-badge>
            </h6>

            <h2 class="flex-column">
              <ion-text class="linear-g-color bold">Pagamento estornado!</ion-text>
              <ion-text> Houve um problema com o seu pedido e o pagamento foi estornado</ion-text>
              <ion-text> Seu dinheiro foi devolvido para a sua conta</ion-text>
            </h2>
          </ion-card-content>
        </ion-card>
      }
      @case ("CANCELED") {
        <ion-card id="pix" class="prevent-animation" mode="md">
          <ion-card-header>
            <ion-card-subtitle> Transação #{{ transaction.id_mercado_pago || transaction.id }} </ion-card-subtitle>
          </ion-card-header>

          <ion-card-content>
            <h6>
              <ion-text class="bold"> Quantidade de Cotas: </ion-text>
              <ion-text class="bold linear-g-color"> {{ transaction.amount_numbers }} </ion-text>
            </h6>

            <h6>
              <ion-text class="bold"> Valor da Cota: </ion-text>
              <ion-text class="bold linear-g-color"> {{ raffle?.quota_value | currency }} </ion-text>
            </h6>

            <h6>
              <ion-text class="bold"> Valor Total: </ion-text>
              <ion-text class="bold linear-g-color"> {{ transaction.amount_cash | currency }} </ion-text>
            </h6>

            <h6>
              <ion-badge class="bold" [color]="colorsStatussesTransaction[transaction.status]"> Pagamento {{ statusesTransaction[transaction.status] }} </ion-badge>
            </h6>

            <h2 class="flex-column">
              <ion-text class="linear-g-color bold">Pagamento cancelado!</ion-text>
              <ion-text> O pagamento não foi confirmado e por isso esse pedido foi cancelado</ion-text>
              <ion-text> Fique tranquilo, nenhum valor foi debitado da sua conta </ion-text>
            </h2>
          </ion-card-content>
        </ion-card>
      }

      @default {
        <ion-card class="prevent-animation" mode="md">
          <ion-card-content>
            <ion-note color="danger"> Ocorreu um erro ao carregar as informações dessa transação </ion-note>
          </ion-card-content>
        </ion-card>
      }
    }

    @if (transaction?.flashNumbers?.length > 0) {
      <ion-card id="social" class="prevent-animation" mode="md">
        <ion-card-content>
          <h2 class="bold">Você foi sorteado com os números:</h2>
          <section class="margin-top-md" id="purchased-numbers">
            @for (num of transaction.flashNumbers; track $index) {
              <li>{{ num | padStart: raffle?.amount_numbers + raffle?.start_at }}</li>
            }
          </section>
          <ion-text class="flex margin-vertical-md"> Entre em contato com o criador da rifa para receber o seu prêmio: </ion-text>
          <div class="flex align-center justify-center">
            <social [bookie]="raffle?.bookie" />
          </div>
        </ion-card-content>
      </ion-card>
    }
  } @else {
    <operation-in-progress />
  }
</div>
