import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { BaseClass } from '@common/globals';

@Component({
  selector: 'app-transactions-wrapper',
  templateUrl: './transactions-wrapper.component.html',
  standalone: true,
  imports: [RouterOutlet],
})
export class TransactionsWrapperComponent extends BaseClass() {
}
