import { Component } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { IonList, IonButton, IonContent, IonAvatar, IonText, IonNote } from '@ionic/angular/standalone';
import {
  AlertService,
  AuthService,
  ClientService,
  LOGO_URL,
  LoadingService,
  MIN_PASSWORD_LENGTH,
  ToastService,
  cpfIsValid,
  hashPassword,
} from '@common/services';
import { Client } from '@common/models';
import { BaseClass } from '@common/utils';
import { AsyncPipe } from '@angular/common';
import { NgxMaskDirective } from 'ngx-mask';
import { FRASE_EFEITO, MIN_CHARACTERS_PASSWORD, MIN_CHARACTERS_USERNAME } from '@common/globals';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  standalone: true,
  imports: [IonNote, IonText, IonAvatar, IonContent, MatFormFieldModule, MatInputModule, IonButton, IonList, ReactiveFormsModule, RouterLink, AsyncPipe, ReactiveFormsModule, NgxMaskDirective],
})
export class RegisterComponent extends BaseClass() {

  redirect: string = null;
  logoUrl = LOGO_URL;
  fraseEfeito = FRASE_EFEITO

  form: FormGroup<{
    name: FormControl<string>,
    cpf: FormControl<string>,
    email: FormControl<string>,
    phone: FormControl<string>,
    password: FormControl<string>,
    confirmPassword: FormControl<string>,
  }> = new FormGroup({
    cpf: new FormControl('', [Validators.required, Validators.minLength(11), Validators.maxLength(11)]),
    email: new FormControl('', [Validators.required, Validators.email]),
    name: new FormControl('', [Validators.required, Validators.minLength(MIN_CHARACTERS_USERNAME)]),
    phone: new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(11)]),
    password: new FormControl('', [Validators.required, Validators.minLength(MIN_CHARACTERS_PASSWORD)]),
    confirmPassword: new FormControl('', [Validators.required, Validators.minLength(MIN_CHARACTERS_PASSWORD)]),
  });

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastService: ToastService,
    private alertService: AlertService,
    private loadingService: LoadingService,
    private authService: AuthService,
    private clientService: ClientService,
  ) {
    super();
  }

  ngOnInit() {
    this.redirect = this.route?.snapshot?.queryParams?.['redirect'] || '/home';
  }


  async register() {
    if (this.form.controls.cpf.invalid) return this.toastService.present({ message: 'CPF inválido', color: 'danger' });
    if (!cpfIsValid(this.form.controls.cpf.value)) return this.toastService.present({ message: 'O número de CPF informado é inválido', color: 'danger' });
    if (this.form.controls.email.invalid) return this.toastService.present({ message: 'E-mail inválido', color: 'danger' });
    if (this.form.controls.name.invalid) return this.toastService.present({ message: 'Nome inválido', color: 'danger' });
    if (this.form.controls.phone.invalid) return this.toastService.present({ message: 'Telefone inválido', color: 'danger' });

    if (this.form.controls.password.value?.length < MIN_PASSWORD_LENGTH) return this.toastService.present({ message: `Senha deve ter no mínimo ${MIN_PASSWORD_LENGTH} caracteres`, color: 'danger' });
    if (this.form.controls.password.invalid) return this.toastService.present({ message: 'Senha inválida', color: 'danger' });
    if (this.form.controls.confirmPassword.invalid) return this.toastService.present({ message: 'Confirmação de senha inválida', color: 'danger' });
    if (this.form.controls.password.value !== this.form.controls.confirmPassword.value) return this.toastService.present({ message: 'Senhas não conferem', color: 'danger' });

    const { confirmPassword, password, ...client } = this.form.value;

    const cliente: Client = {
      ...client,
      password: hashPassword(password),
    }

    const loading = await this.loadingService.presentLoading('Salvando cadastro, aguarde...');
    this.form.disable();

    this.clientService.create(cliente).then(async (res) => {
      if (!res.id) throw new Error('Erro ao salvar cadastro');

      this.authService.login<'client'>(client.cpf, password, true).then((login) => {
        if (!login.token) {
          this.toastService.present({ message: 'Cadastro criado com sucesso, agora você pode fazer login', color: 'success' });
          this.router.navigate(['login'], { queryParams: { redirect: this.redirect }, replaceUrl: true });
          return;
        }

        this.authService.setSession({ ...login, timestamp: Date.now() });
        this.toastService.present({ message: 'Cadastro realizado com sucesso', color: 'success' });
        this.router.navigateByUrl(this.redirect, { replaceUrl: true });
      }).catch((err) => {
        throw new Error('Erro ao fazer login');
      });
    }).catch((err) => {
      this.alertService.presentAlert('Erro', 'Ocorreu um erro ao salvar o cadastro, tente novamente.', 'danger');
      console.log(err);
      this.form.enable();
    }).finally(() => {
      loading.dismiss();
    });
  }
}
