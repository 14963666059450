import { Component, OnInit } from '@angular/core';
import { IonContent, IonList, IonItem, IonLabel, IonText, IonNote, IonChip, IonBadge, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent } from "@ionic/angular/standalone";
import { Client, ColorsStatusTransaction, OperationInProgressComponent, StatusesTransaction, getCurrentSession } from '@common/models';
import { BaseClass, PaginatorConfig } from '@common/globals';
import { TransactionService } from '@common/services';
import { CurrencyPipe } from '@angular/common';
import { TransactionExpanded } from '@common/models';
import { RouterLink } from '@angular/router';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss'],
  standalone: true,
  imports: [IonCardContent, IonCardSubtitle, IonCardTitle, IonCardHeader, IonCard, IonBadge, IonChip, IonNote, IonText, IonLabel, IonItem, IonList, IonContent, CurrencyPipe, RouterLink, OperationInProgressComponent, MatPaginatorModule,],
})
export class TransactionsComponent extends BaseClass() implements OnInit {

  transactions: Array<TransactionExpanded>;

  session = getCurrentSession<'client'>();

  statusesTransaction = StatusesTransaction;
  colorStatus = ColorsStatusTransaction;

  loadingMore: boolean = true;
  paginatorConfig: PaginatorConfig;

  cliente: Client;

  constructor(
    private transactionService: TransactionService,
  ) {
    super();
  }

  ngOnInit() {
    const sub = this.session.subscribe({
      next: (res) => {
        this.cliente = res.client;
        if (!this.transactions && this.cliente?.id) this.getTransactionsClient();
      }
    });
    this.subs.push(sub);
  }

  getTransactionsClient() {
    this.loadingMore = true;
    this.transactionService.getTransactionsClient(this.cliente?.id, this.paginatorConfig?.page || 1).then((res) => {
      const { transactions, ...paginatorConfig } = res?.purchasedRaffles;
      this.transactions = transactions || [];
      this.paginatorConfig = paginatorConfig;
    }).catch((err) => {
      console.log(err);
      if (err['status'] === 404) this.transactions = [];
    }).finally(() => {
      this.loadingMore = false;
    });
  }

  handlePageEvent(e: PageEvent) {
    this.paginatorConfig.page = e.pageIndex + 1;
    this.getTransactionsClient();
  }

}
