<ion-content id="container">
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-menu-button color="primary" />
      </ion-buttons>

      <ion-title>
        <div id="logo-bookie">
          @if ((raffleBS | async)?.bookie?.logo) {
            <ion-img [src]="(raffleBS | async)?.bookie?.logo" />
          } @else {
            <ion-text class="linear-g-color"> {{ (raffleBS | async)?.bookie?.name | uppercase }} </ion-text>
          }
        </div>
      </ion-title>
    </ion-toolbar>
  </ion-header>

  <div id="router">
    <router-outlet id="raffle-wrapper" />
  </div>
</ion-content>
