import { Component } from '@angular/core';
import { Router } from '@angular/router';
import {
  BaseClass,
  LoadingService,
  MinMaxNumberPipe,
  PadStartPipe,
  RAFFLES_CONFIG,
  ToastService,
  getCurrentSession,
} from '@common/globals';
import { OperationInProgressComponent } from '@common/components';
import {
  IonContent,
  IonSplitPane,
  IonGrid,
  IonRow,
  IonCol,
  IonChip,
  IonCardContent,
  IonFabButton,
  IonIcon,
  IonInput,
  IonButton,
  IonText,
  IonNote,
  IonRange,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonMenuButton,
  IonLabel,
  IonAvatar,
  IonImg,
} from "@ionic/angular/standalone";
import { ImagesComponent } from './images/images.component';
import { AsyncPipe, CurrencyPipe, DatePipe, UpperCasePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { addIcons } from 'ionicons';
import { removeOutline, addOutline } from 'ionicons/icons';
import { raffle$ } from '../raffle-wrapper.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';

addIcons({ removeOutline, addOutline });

const IONIC_COMPONENTS = [
  IonButton, IonInput, IonIcon, IonFabButton, IonCardContent, IonChip, IonMenuButton,
  IonCol, IonRow, IonGrid, IonImg, IonAvatar, IonLabel,
  IonSplitPane, IonContent, IonTitle, IonButtons, IonToolbar, IonHeader, IonRange, IonNote, IonText,];

const PIPES = [UpperCasePipe, CurrencyPipe, DatePipe, MinMaxNumberPipe, AsyncPipe]

@Component({
  selector: 'app-raffle',
  templateUrl: './raffle.component.html',
  styleUrls: ['./raffle.component.scss'],
  standalone: true,
  imports: [IONIC_COMPONENTS, PIPES, ImagesComponent, FormsModule, OperationInProgressComponent, PadStartPipe, MatChipsModule, MatIconModule],
  providers: [CurrencyPipe],
})
export class RaffleComponent extends BaseClass() {

  operationInProgress: string = 'Verificando ação...';

  raffle = raffle$;

  quantidadeRaffles: number = 10;

  raffleConfig = RAFFLES_CONFIG;

  selectedNumbers: number;

  amountButton: number = RAFFLES_CONFIG.AMOUNT_BUTTON;
  min: number = 0;
  max: number = 0;

  session = getCurrentSession<'client'>();

  combos: Array<number> = [];

  constructor(
    private router: Router,
    private loadingService: LoadingService,
    private toastService: ToastService,
    private currencyPipe: CurrencyPipe,
  ) {
    super();

    const sub = this.raffle.subscribe({
      next: (value) => {
        if (!value) return;
        this.min = Math.min(RAFFLES_CONFIG.MIN_NUMBERS, this.raffle?.value?.qtd_array);
        this.max = Math.min(this.raffle?.value?.qtd_array, Math.floor(RAFFLES_CONFIG.MAX_CURRENCY / this.raffle?.value?.quota_value));

        this.combos = [10, 50, 100, 200, 300, 500].filter((v) => {
          return v <= this.max;
        });

        this.selectedNumbers = this.min;
      }
    });
    this.appendSubscription(sub);
  }

  changeAmount(value: number, op: 'minus' | 'plus') {
    this.selectedNumbers += (op === 'minus' ? -value : value);
    this.selectedNumbersChanged();
  }

  setAmount(amount: number) {
    this.selectedNumbers = amount;
    this.selectedNumbersChanged();
  }

  selectedNumbersChanged() {
    if (this.selectedNumbers < this.min) {
      this.selectedNumbers = this.min;
      this.toastService.present({ message: `O número de rifas não pode ser menor que ${this.min}`, color: 'danger' });
      return false;
    }

    if ((this.raffle?.value?.quota_value * this.selectedNumbers) > RAFFLES_CONFIG.MAX_CURRENCY) {
      this.selectedNumbers = Math.floor(RAFFLES_CONFIG.MAX_CURRENCY / this.raffle?.value?.quota_value);
      this.toastService.present({ message: `O valor máximo permitido é de ${this.currencyPipe.transform(RAFFLES_CONFIG.MAX_CURRENCY)}`, color: 'danger' });
      return false;
    }

    return true;
  }

  async buyRaffles() {
    const loading = await this.loadingService.presentLoading('Verificando...');

    loading.dismiss();

    if (!this.session?.value?.client) {
      this.toastService.present({ message: 'Você precisa estar logado para realizar essa operação', color: 'danger' });
      this.router.navigate(['login'], {
        queryParams: {
          redirect: `/home/promotion/${btoa(this.raffle?.value?.id)}/payment?amount=${this.selectedNumbers}`
        }
      });
    } else {
      this.router.navigate(['/home', 'promotion', btoa(this.raffle.value.id), 'payment'], { queryParams: { amount: this.selectedNumbers } });
    }

  }

}
