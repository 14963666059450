<ion-content>
  <ion-split-pane [when]="!isMobile" contentId="home-outlet">
    <ion-menu contentId="home-outlet" type="push">
      <sidenav />
    </ion-menu>

    <div id="home-outlet" [class.is-promotion-page]="isPromotionPage">
      @if (!isPromotionPage) {
        <ion-header>
          <ion-toolbar>
            <ion-buttons slot="start">
              <ion-menu-button color="primary" />
            </ion-buttons>

            <ion-title>
              <ion-text class="linear-g-color"> {{ title | async }} </ion-text>
            </ion-title>
          </ion-toolbar>
        </ion-header>
      }

      <router-outlet id="home-outlet" />
    </div>
  </ion-split-pane>
</ion-content>
