<div id="container">
  <ion-list lines="none" class="padding-sm">
    @for (page of pages; track $index) {
      <ion-menu-toggle [autoHide]="false">
        <ion-item class="hover-pointer" [routerLink]="page.path" routerLinkActive="active" #route="routerLinkActive">
          <ion-icon slot="start" [name]="page.icon + (route.isActive ? '' : '-outline')" />
          <ion-label> {{ page.title }} </ion-label>
        </ion-item>
      </ion-menu-toggle>
    }

    @if ((session | async)?.token) {
      <ion-item class="hover-pointer" (click)="logout()" id="logout">
        <ion-icon slot="start" name="log-out" />
        <ion-label>Sair </ion-label>
      </ion-item>
    }
  </ion-list>
</div>
