import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseClass, Bookie, BookieService, LoadingService, RaffleService, Raffles, RafflesListComponent, ToastService, SocialComponent } from '@common/globals';
import { IonContent, IonHeader, IonToolbar, IonButtons, IonTitle, IonImg, IonText, IonMenuButton, IonIcon, IonButton } from "@ionic/angular/standalone";
import { UpperCasePipe } from '@angular/common';

@Component({
  selector: 'app-raffles-bookie',
  templateUrl: './raffles-bookie.component.html',
  styleUrls: ['./raffles-bookie.component.scss'],
  standalone: true,
  imports: [IonButton, IonIcon, IonMenuButton, IonText, IonImg, IonTitle, IonButtons, IonToolbar, IonHeader, IonContent, RafflesListComponent, SocialComponent, UpperCasePipe],
})
export class RafflesBookieComponent extends BaseClass() implements OnInit {

  bookie_id: string;
  raffles: Raffles;

  bookie: Bookie;

  constructor(
    private route: ActivatedRoute,
    private raffleService: RaffleService,
    private bookieService: BookieService,
    private toastService: ToastService,
    private loadingService: LoadingService,
  ) {
    super();
  }

  ngOnInit() {
    const bookie_id = this.route.snapshot.params?.['bookie_id'];
    if (bookie_id) {
      this.bookie_id = bookie_id;
      this.getRafflesBookie();
    }
  }

  async getRafflesBookie() {
    const loading = await this.loadingService.presentLoading();

    try {
      const [bookieRes, rafflesRes] = await Promise.all([
        this.bookieService.getBookie(this.bookie_id),
        this.raffleService.getRafflesByBookieId(this.bookie_id, 'ACTIVE'),
      ]);

      if (bookieRes?.bookie) this.bookie = bookieRes.bookie;

      if (rafflesRes?.raffles?.length >= 0) this.raffles = rafflesRes.raffles;
      else this.toastService.present({ message: "Nehuma ação encontrada", color: "warning" });
    } catch (err) {
      console.error(err);
      this.toastService.present({ message: "Erro ao buscar ações desse criador", color: "danger" });
    } finally {
      loading.dismiss();
    }
  }

}
