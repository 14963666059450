import { Component, Input } from '@angular/core';

import { BaseClass } from '@common/globals';
import { IonImg, IonRow } from "@ionic/angular/standalone";

@Component({
  selector: 'images',
  templateUrl: './images.component.html',
  styleUrls: ['./images.component.scss'],
  standalone: true,
  imports: [IonRow, IonImg,],
})
export class ImagesComponent extends BaseClass() {

  @Input() images: Array<string> = [];
  currentImage: string;

  ngOnInit() {
    this.currentImage = this.images[0] || null;
  }

}
