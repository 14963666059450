import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { ANIMATION_DURATION, APP_VERSION, BORDER_RADIUS, BUTTON_HEIGHT, ENABLE_TRANSPARENCY, MENU_WIDTH, SPACING_LG, SPACING_MD, SPACING_SM, SPACING_XL } from '@common/globals';

bootstrapApplication(AppComponent, appConfig).then(() => {
  document.body.setAttribute('app-version', APP_VERSION);
  document.body.classList.toggle('transparent', ENABLE_TRANSPARENCY);

  document.documentElement.style.setProperty('--button-height', `${BUTTON_HEIGHT}px`);

  document.documentElement.style.setProperty('--spacing-sm', `${SPACING_SM}px`);
  document.documentElement.style.setProperty('--spacing-md', `${SPACING_MD}px`);
  document.documentElement.style.setProperty('--spacing-lg', `${SPACING_LG}px`);
  document.documentElement.style.setProperty('--spacing-xl', `${SPACING_XL}px`);

  document.documentElement.style.setProperty('--borderRadius', `${BORDER_RADIUS}px`);

  document.documentElement.style.setProperty('--animation-duration', `${ANIMATION_DURATION}ms`);
  document.documentElement.style.setProperty('--menu-width', `${MENU_WIDTH}px`);
})
  .catch((err) => console.error(err));
