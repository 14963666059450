<div id="images" class="flex-column">
  @if (currentImage) {
    <div id="current-image">
      <ion-img [src]="currentImage" />
    </div>
  }

  <div id="images-list">
    <div id="list">
      @for (image of images; track $index) {
        <div class="image">
          <ion-img [src]="image" [class.current]="currentImage === image" (click)="currentImage = image" />
        </div>
      }
    </div>
  </div>
</div>
