import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AlertService,
  BaseClass,
  LoadingService,
  RaffleService,
  TAXA_MERCADO_PAGO,
  ToastService,
  TransactionCreate,
  copyClipboard,
  getCurrentSession,
} from '@common/globals';
import { OperationInProgressComponent } from '@common/components';
import { IonContent, IonCard, IonCardSubtitle, IonCardHeader, IonCardTitle, IonCardContent, IonText, IonButton, IonIcon, IonSpinner, IonImg, IonInput, IonTextarea } from "@ionic/angular/standalone";
import { PaymentService, FirebaseTransactionsService } from '@common/services'
import { Transaction, Raffle } from '@common/models';
import { CurrencyPipe, JsonPipe, PercentPipe } from '@angular/common';
import { TransactionMPResponse } from '@common/api';
import { MatIconModule } from '@angular/material/icon';
import { RoundNumberPipe } from '../../../../../../../common/src/lib/pipes/round-number.pipe';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
  standalone: true,
  imports: [IonTextarea, IonInput, IonImg, IonSpinner, IonIcon, IonButton, IonText, IonCardContent, IonCardTitle, IonCardHeader, IonCardSubtitle, IonCard, IonContent, OperationInProgressComponent, JsonPipe, CurrencyPipe, MatIconModule, RoundNumberPipe, PercentPipe],
  providers: [RoundNumberPipe],
})
export class PaymentComponent extends BaseClass() {

  raffle: Raffle;

  amount: number;
  transactionMP: string;
  raffleIDEnconded: string;

  transaction: Transaction;
  transactionCreate: TransactionCreate;
  transactionMPResponse: TransactionMPResponse;

  gerandoPix: boolean = false;

  operationInProgress: boolean = true;

  taxaMP = TAXA_MERCADO_PAGO;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private paymentService: PaymentService,
    private loadingService: LoadingService,
    private raffleService: RaffleService,
    private firebaseTransactionsService: FirebaseTransactionsService,
    private toastService: ToastService,
    private alertService: AlertService,
    private roundNumberPipe: RoundNumberPipe,
  ) {
    super();
  }

  ngOnInit() {
    this.route.queryParams.subscribe({
      next: (params) => {
        this.amount = +params?.['amount'];
        this.transactionMP = params?.['transaction_mp'];
        this.raffleIDEnconded = this.route.parent.snapshot.params['id'];
        this.initialize();
      }
    });
  }

  async initialize() {
    this.raffle = (await this.raffleService.getRafflePromotion(this.raffleIDEnconded));
    const currentUser = getCurrentSession<'client'>()?.value?.client;
    const bookieRaffle = this.raffle?.bookie;

    if (!currentUser) {
      this.router.navigate(['..'], { relativeTo: this.route });
      return;
    }

    if (this.amount) {
      this.transactionCreate = {
        amount: this.amount * this.raffle?.quota_value,
        cpf_user: bookieRaffle?.cpf,
        id_bookie: bookieRaffle?.id,
        id_raffle: this.raffle?.id,
        id_user: currentUser?.id,
        qtd_numbers: this.amount,
        url_pagamento: bookieRaffle?.payment_url,
        user_email: currentUser?.email,
      }

      this.operationInProgress = false;
    } else if (this.transactionMP) {
      this.subscribeToTransactionFB(this.transactionMP);
    }
  }

  private subscribeToTransactionFB(idMP: string) {
    this.firebaseTransactionsService.getTransactionById(idMP, async (doc) => {
      this.transactionCreate = null;
      const status = doc?.data()?.status;

      switch (status) {
        case 'PAID':
          const _alert = await this.alertService.presentAlert("Pagamento efetuado!", "O pagamento foi efetuado com sucesso, boa sorte!");
          await _alert.onDidDismiss();
          this.router.navigate(['/home', 'my-orders'], { replaceUrl: true });
          return;

        case 'CANCELED':
          this.alertService.presentAlert("Pagamento cancelado", "O pagamento foi cancelado, tente novamente.");
          this.router.navigate(['/home', 'promotion', this.raffleIDEnconded], { replaceUrl: true });
          return;

        case 'REFUND':
          this.alertService.presentAlert("Pagamento estornado", "Ocorreu um erro ao processar o seu pedido e por isso o pagamento foi estornado");
          this.router.navigate(['/home', 'promotion', this.raffleIDEnconded], { replaceUrl: true });
          return;

        default:
          break;
      }

      this.transaction = {
        ...doc.data(),
      };

      this.operationInProgress = false;
    });
  }

  async gerarPix() {
    const loading = await this.loadingService.presentLoading('Gerando PIX...');
    this.gerandoPix = true;

    this.paymentService.createPIX({ ...this.transactionCreate, amount: this.roundNumberPipe.transform((this.transactionCreate.amount * this.taxaMP), "UP") + this.transactionCreate.amount }).then((res) => {
      if (!res.message?.qrCode || !res.message?.id_mercado_pago) {
        this.alertService.presentAlert('Erro ao gerar PIX', 'Não foi possível gerar o PIX, tente novamente mais tarde.');
        return;
      }

      this.router.navigate(['/home', 'orders', 'details', res.message.id_mercado_pago]);
      // this.transactionMPResponse = res.message;
      // this.subscribeToTransactionFB(res.message.id_mercado_pago);
      // updateUrl(this.ngLocation, { transaction_mp: res.message.id_mercado_pago });
    }).catch((err) => {
      console.log(err);
      this.alertService.presentAlert('Erro ao gerar PIX', 'Não foi possível gerar o PIX, tente novamente mais tarde.');
    }).finally(() => {
      loading.dismiss();
      this.gerandoPix = false;
    });
  }

  async copyCodigoPix() {
    const copied = await copyClipboard(this.transaction?.pix_copia_cola);
    if (copied) return this.toastService.present({ message: 'Código copiado', color: 'success' });
    this.toastService.present({ message: 'Erro ao copiar código', color: 'danger' });
  }

}
