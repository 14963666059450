import { Component } from '@angular/core';
import { ActivationStart, Router, RouterOutlet } from '@angular/router';
import { APP_ID, RECAPTCHA_SITE_KEY, title$ } from '@common/api';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY, ReCaptchaV3Service } from "ng-recaptcha";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  // providers: [{ provide: RECAPTCHA_V3_SITE_KEY, useValue: RECAPTCHA_SITE_KEY }],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {

  constructor(
    private router: Router,
    // private recaptchaV3Service: ReCaptchaV3Service,
  ) {}

  ngOnInit() {
    APP_ID.next('client');

    this.router.events.subscribe({
      next: (res) => {
        if (res instanceof ActivationStart && res.snapshot.routeConfig.title) {
          title$.next(res.snapshot.routeConfig.title.toString());
        }
      }
    });
  }

  // captchaResolved(captchaResponse: string) {
  //   console.log(`Resolved captcha with response: ${captchaResponse}`);
  // }
}
