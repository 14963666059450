import { Component } from '@angular/core';
import { IonHeader, IonToolbar, IonTitle, IonContent, IonButtons, IonText, IonSplitPane, IonMenuButton, IonMenu } from "@ionic/angular/standalone";
import { BaseClass, title$ } from '@common/globals';
import { SidenavComponent } from './sidenav/sidenav.component';
import { AsyncPipe } from '@angular/common';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';

const IONIC_COMPONENTS = [IonSplitPane, IonText, IonButtons, IonContent, IonTitle, IonToolbar, IonHeader, IonMenuButton, IonMenu];

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  standalone: true,
  imports: [IONIC_COMPONENTS, SidenavComponent, AsyncPipe, RouterOutlet],
})
export class HomeComponent extends BaseClass() {

  title = title$;

  isPromotionPage: boolean = false;

  constructor(private router: Router) {
    super();
    router.events.subscribe({
      next: (res) => {
        if (res instanceof NavigationEnd) {
          this.isPromotionPage = res.url.includes('/promotion/');
        }
      }
    });
  }

}
