<ion-content>
  @if (client) {
    <ion-list>
      <ion-card class="prevent-animation" [formGroup]="form">
        <ion-list-header> Seus dados </ion-list-header>

        <ion-card-content class="flex-column gap-sm">
          <mat-form-field appearance="outline">
            <input matInput placeholder="Nome" formControlName="name" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <input matInput placeholder="E-mail" formControlName="email" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <input matInput placeholder="CPF" formControlName="cpf" mask="000.000.000-00" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <input matInput placeholder="Telefone" formControlName="phone" mask="(00)0000-0000||(00)00000-0000" />
          </mat-form-field>

          <ion-button fill="solid" (click)="save()"> Atualizar </ion-button>
        </ion-card-content>
      </ion-card>
    </ion-list>
  } @else {
    <operation-in-progress />
  }
</ion-content>
