import { AsyncPipe, UpperCasePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { IonHeader, IonToolbar, IonButtons, IonTitle, IonImg, IonText, IonMenuButton, IonContent } from "@ionic/angular/standalone";
import { BaseClass } from '@common/globals';
import { Raffle } from '@common/models';
import { AlertService, RaffleService } from '@common/services';
import { BehaviorSubject } from 'rxjs';

export const raffle$: BehaviorSubject<Raffle> = new BehaviorSubject(null);

@Component({
  selector: 'app-raffle-wrapper',
  templateUrl: './raffle-wrapper.component.html',
  styleUrls: ['./raffle-wrapper.component.scss'],
  standalone: true,
  imports: [IonContent, IonText, IonImg, IonTitle, IonButtons, IonToolbar, IonHeader, RouterOutlet, IonMenuButton, UpperCasePipe, AsyncPipe],
})
export class RaffleWrapperComponent extends BaseClass() {

  raffleBS = raffle$;

  operationInProgress: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private raffleService: RaffleService,
  ) {
    super();
  }

  ngOnInit() {
    this.initialize();
  }

  private async initialize() {
    this.operationInProgress = 'Verificando ação..';

    const raffleId = this.route.snapshot.paramMap.get('id');
    if (!raffleId) return this.alertService.presentAlert('Erro', 'Ação não encontrada', 'danger');
    this.getRaffle(raffleId);
  }

  async getRaffle(id: string) {
    try {
      const raffle = await this.raffleService.getRafflePromotion(id);
      if (!raffle) throw new Error('Ação não encontrada', { cause: 'Ação não encontrada' });
      if (raffle.qtd_array === 0) throw new Error('Ação não disponível', { cause: 'Ação não disponível' });
      if (raffle.status !== 'ACTIVE') throw new Error('Ação não disponível', { cause: 'Ação não disponível' });
      raffle$.next(raffle);
    } catch (error) {
      console.log(error);
      let message = (error as any)?.['cause'] || 'Erro ao buscar rifa';
      if (error instanceof HttpErrorResponse && error.status === 404) message = 'Ação não encontrada';
      this.alertService.presentAlert('Erro', message, 'danger');
      this.router.navigate(['/home'], { replaceUrl: true });
    } finally {
      this.operationInProgress = null;
    }
  }
}
