<ion-content class="padding-sm">
  @if (operationInProgress) {
    <operation-in-progress />
  } @else {
    @if (transactionCreate) {
      <ion-card mode="md" class="prevent-animation" id="resumo">
        <ion-card-header>
          <ion-card-title> Resumo do pedido </ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <h6>
            <ion-text class="bold"> Quantidade de Cotas: </ion-text>
            <ion-text color="success" class="bold"> {{ transactionCreate.qtd_numbers }} </ion-text>
          </h6>
          <h6>
            <ion-text class="bold"> Valor da Cota: </ion-text>
            <ion-text color="success" class="bold"> {{ transactionCreate.amount / transactionCreate.qtd_numbers | currency }} </ion-text>
          </h6>
          <h6>
            <ion-text class="bold"> Taxas({{ taxaMP | percent: "1.0-2" }}): </ion-text>
            <ion-text color="success" class="bold"> {{ transactionCreate.amount * taxaMP | roundNumber: "UP" | currency }} </ion-text>
          </h6>
          <h6>
            <ion-text class="bold"> Valor Total: </ion-text>
            <ion-text color="success" class="bold"> {{ (transactionCreate.amount * taxaMP | roundNumber: "UP") + transactionCreate.amount | currency }} </ion-text>
          </h6>

          <ion-button class="margin-top-md" fill="solid" (click)="gerarPix()" [disabled]="gerandoPix">
            <ion-text> Gerar Pix </ion-text>
            @if (gerandoPix) {
              <ion-spinner class="margin-left-sm" name="dots" />
            } @else {
              <mat-icon class="margin-left-sm">pix</mat-icon>
            }
          </ion-button>
        </ion-card-content>
      </ion-card>
    } @else if (transaction) {
      <div class="content">
        <ion-card id="pix" class="prevent-animation" mode="md">
          <div id="qr">
            <div class="corner tl"></div>
            <div class="corner tr"></div>
            <div class="corner bl"></div>
            <div class="corner br"></div>
            <img [src]="'data:image/png;base64,' + transaction.pix_qr_code" />
          </div>
          <ion-card-header>
            <ion-card-subtitle class="linear-g-color bold"> Agora é só pagar </ion-card-subtitle>
          </ion-card-header>

          <ion-card-content>
            <h6 class="margin-bottom-md">
              Leia o código QR acima com o aplicativo do seu banco para realizar o pagamento no valor de <span class="linear-g-color bold"> {{ transaction.amount_cash | currency }} </span>
            </h6>
            <h6>Ou, se preferir, copie o código abaixo e cole no aplicativo do seu banco:</h6>

            <ion-textarea (click)="copyCodigoPix()" class="margin-top-md" [readonly]="true" [value]="transaction.pix_copia_cola" [autoGrow]="true" />
          </ion-card-content>
        </ion-card>
      </div>
    }
  }
</ion-content>
